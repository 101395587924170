import React from "react";
import c from "./styles.module.scss";

const Announcement = ({ props }) => {
	const { text } = props;
	return (
		<div className={c.announcement} id="announcement">
			<div className={c.inner}>
				<p className={c.text}>{text}</p>
			</div>
		</div>
	);
};

export default Announcement;
