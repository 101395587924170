import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import PostPreview from "../components/postPreview";
import CosmicController from "../controllers/cosmicController";
import Spinner from "../components/spinner"; 
import PostMetaHead from "../components/postMetaHead";
const PostPage = () => {
	const { slug } = useParams();
	const location = useLocation();

	const [post, setPost] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const getPostBySlug = async () => {
		setLoading(true);
		try {
			const { data } = await CosmicController.getPostBySlug(slug);
			setPost(data);
		} catch (error) {
			setError("При подгрузке поста произошла ошибка");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getPostBySlug();
	}, [slug]);

	if (loading) {
		return <Spinner />;
	}

	if (error) {
		return <p>{error}</p>;
	}

	if (!post || !post.metadata) {
		return null;
	}

	
	return (
		<>
			<PostMetaHead post={post} />

			<PostPreview post={post} />
		</>
	);
};

export default PostPage;
