import React from "react";
import { Helmet } from "react-helmet-async";

const PostMetaHead = ({ post }) => {
	const { slug, title, metadata } = post;
	const { "post-seo-description": postDesc, "post-desktop-image": postImage } =
		metadata;

	const jsonLd = {
		"@context": "https://schema.org",
		"@type": "BlogPosting",
		mainEntityOfPage: {
			"@type": "WebPage",
			"@id": `https://g-factory.kz/blog/${slug}`,
		},
		headline: title,
		image: postImage.imgix_url,
		author: {
			"@type": "Organization",
			name: "G-Factory",
			url: "https://g-factory.kz/",
		},
		publisher: {
			"@type": "Organization",
			name: "G-Factory",
			logo: {
				"@type": "ImageObject",
				url: "https://g-factory.kz/logo.svg",
			},
		},
		datePublished: "2024-11-05",
		dateModified: "2024-11-20",
	};

	return (
		<Helmet>
			{/* Standard metadata */}
			<title>{title}</title>
			<meta name="description" content={postDesc} />
			<link rel="canonical" href={`https://g-factory.kz/blog/${slug}`} />

			{/* Open Graph metadata */}
			<meta property="og:type" content="article" />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={postDesc} />
			<meta property="og:url" content={`https://g-factory.kz/blog/${slug}`} />
			<meta property="og:image" content={postImage.imgix_url} />
			<meta property="og:site_name" content="G-Factory" />

			{/* Twitter metadata */}
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={postDesc} />
			<meta name="twitter:image" content={postImage.imgix_url} />
			<meta name="twitter:creator" content="@GFactory" />

			{/* JSON-LD schema */}
			<script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
		</Helmet>
	);
};

export default PostMetaHead;
